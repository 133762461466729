import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";
import { useParams } from "react-router-dom";

import ListSearch from "components/Entities/ListControls/ListSearch";
import ListHeaderButton from "components/Lists/ListHeaderButton";
import ListHeaderButtons from "components/Lists/ListHeaderButtons";
import ListHeaderRight from "components/Lists/ListHeaderRight";
import ListHeaderWrapper from "components/Lists/ListHeaderWrapper";
import ListLoadMore from "components/Lists/ListLoadMore";
import ListSort from "components/Lists/ListSort";
import NoResultsMessage from "pages/Common/NoResultsMessage";

import { insightStyles } from "../../InsightsStyles";
import ContactsHeaderTools from "./ContactsHeaderTools";
import InsightsContactItem from "./InsightsContactItem";
import LoadingSkeleton from "./LoadingContacts";

import * as sortConstants from "constants/sort";
import { keysToConstants } from "constants/sort";
import loadContactsList from "sagas/pagination/lists/loadContactList";
import { makeSelectSpecificContact } from "selectors/contact";
import { selectListIds } from "selectors/pagination";
import {
  getContactsForms,
  itemsFromContactData,
} from "utils/contacts/getFormattedContacts";

import useList from "hooks/useList";
import useLoadListEffect from "hooks/useLoadListEffect";
import useReduxState from "hooks/useReduxState";
import useSearchTerm from "hooks/useSearchTerm";
import { useStyles } from "hooks/useStyles";
import userHasInternalPermission from "hooks/useUserHasInternalPermission";

import colours from "styles/colours";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  contactContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    margin: "0 auto",
    padding: "0",
  },
  otherContactContainer: {
    ...insightStyles.insightsContent,

    border: `1px solid ${colours.borderGrey}`,
    borderRadius: 0,

    [ScreenSizes.mdAndAbove]: {
      padding: 0,
    },
    [ScreenSizes.lgAndAbove]: {
      borderRadius: 8,
      boxShadow: colours.InsightCardBoxShadow,
    },

    padding: 0,

    [ScreenSizes.xlAndAbove]: {
      padding: 0,
    },
  },
  showToggleText: insightStyles.showToggleText,
  showToggleArrow: insightStyles.showToggleArrow,
  addMarginTop: {
    [ScreenSizes.lgAndAbove]: {
      marginTop: "1rem",
    },
  },
};

const listSortItems = [
  sortConstants.SORT_ORDER_RELEVANCE,
  sortConstants.SORT_ORDER_ALPHABETICAL,
];

const Contacts = (props) => {
  const {
    proData,
    podcast,
    compact,
    showCompany,
    showVotes,
    showHeaderTools,
    entityType,
  } = props;
  const { styles } = useStyles(baseStyles, props);
  const [searchValue] = useState("");
  const [activeTab, setActiveTab] = useState("podcast_all");
  const userIsInternal = userHasInternalPermission();
  const podcastId = podcast?.get("id");

  const contactForms = getContactsForms(proData);

  const listKey = `podcast_contacts_${podcastId}`;

  const list = useList(listKey);

  const { loading } = list;
  const { sort: sortParamURL } = useParams();
  const { SORT_ORDER_RELEVANCE } = sortConstants;
  const sortKey = keysToConstants[sortParamURL] || SORT_ORDER_RELEVANCE;

  const staticFilters = {
    podcast: {
      value: { contact_type: activeTab, podcast_id: podcastId },
    },
  };

  useLoadListEffect(listKey, {
    key: listKey,
    sort: sortKey,
    pageSize: 5,
    list_type: "contacts_filtered",
    loadListAction: loadContactsList,
    filters: staticFilters,
    dataId: "network-contact-list-section",
  });

  const contactIds = useReduxState(
    (state) => {
      return selectListIds(state, listKey);
    },
    [listKey]
  );

  const contacts = useReduxState(
    (state) => {
      const selectSpecificContact = makeSelectSpecificContact();

      const contacts = contactIds?.map((id) =>
        selectSpecificContact(state, id)
      );

      return contacts;
    },
    [contactIds]
  );

  const { term } = useSearchTerm({ listKey });

  const renderBlank = (noSearchResult) =>
    noSearchResult ? (
      <NoResultsMessage content="No Results" />
    ) : (
      <NoResultsMessage
        entity={podcast}
        entityType={"podcast"}
        label="contacts"
        subject="request additional contacts"
        proData={proData}
      />
    );

  const items = [
    {
      label: "All",
      id: "podcast_all",
    },
    {
      label: "Added by Podcaster",
      id: "podcast_owned",
    },
  ];

  const renderContacts = () => {
    const formattedContacts = itemsFromContactData(contacts)
      .filter((contact) => contact.contactableType !== "network")
      .toJS();
    return (
      <Fragment>
        <div className={css(styles.otherContactContainer)}>
          <InsightsContactItem
            items={formattedContacts}
            podcast={podcast}
            maxItems={contacts?.size}
            compact={compact}
            showCompany={showCompany}
            showVotes={showVotes}
            searchValue={searchValue}
            styles={props?.styles}
          />
        </div>
        <ListLoadMore listKey={listKey} />
      </Fragment>
    );
  };

  const showcontactLinkButton = contactForms?.size > 0;
  const [value, setValue] = useState("");

  return (
    <>
      {showHeaderTools && userIsInternal && (
        <>
          <ContactsHeaderTools
            items={items}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            entityType={entityType || "podcast"}
            entityId={podcast?.get("id")}
            hideSearch
          />
          <ListHeaderWrapper noPadding>
            <ListHeaderButtons>
              <ListHeaderButton>
                <ListSort listKey={listKey} include={listSortItems} />
              </ListHeaderButton>
            </ListHeaderButtons>
            <ListHeaderRight>
              <ListSearch
                listKey={listKey}
                placeholder={"Search this list..."}
                value={value}
                setValue={setValue}
              />
            </ListHeaderRight>
          </ListHeaderWrapper>
        </>
      )}
      <div
        className={css(
          styles.contactContainer,
          !showcontactLinkButton && styles.addMarginTop
        )}
      >
        {loading && contactForms.size === 0 ? (
          <LoadingSkeleton styles={props?.styles} />
        ) : !loading && contacts.size === 0 && term?.length ? (
          renderBlank(true)
        ) : !loading && contacts.size > 0 ? (
          renderContacts()
        ) : (
          renderBlank()
        )}
      </div>
    </>
  );
};

Contacts.propTypes = {
  podcast: PropTypes.instanceOf(Map),
  proData: PropTypes.instanceOf(Map),
  passedRef: PropTypes.object,
  compact: PropTypes.bool,
  showCompany: PropTypes.bool,
  showHeaderTools: PropTypes.bool,
  showLoadMore: PropTypes.bool,
  entityType: PropTypes.string,
};

Contacts.defaultProps = {
  podcast: null,
  passedRef: null,
  proData: Map(),
  compact: false,
  showCompany: false,
  showHeaderTools: false,
  showLoadMore: false,
  entityType: "",
};

export default Contacts;

import { faCopy } from "@fortawesome/free-regular-svg-icons/faCopy";
import { faUpRightFromSquare } from "@fortawesome/pro-duotone-svg-icons/faUpRightFromSquare";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { faRss } from "@fortawesome/pro-solid-svg-icons/faRss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import { getClickableLink } from "utils/contacts/getFormattedContacts";

import { useStyles } from "hooks/useStyles";
import useUserHasPro from "hooks/useUserHasPro";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  rssContainer: {
    display: "grid",
    gridTemplateRows: "max-content max-content",
    gridGap: "0rem",
    placeItems: "center",
  },
  rss: {
    fontSize: "1rem",
    color: "var(--color-positive)",
  },
  rssText: {
    ...gStyles.avalonBold,
    color: "var(--color-positive)",
    margin: 0,
    padding: 0,
    fontSize: "0.813rem",
  },
  itemImage: {
    display: "flex",
    flex: "0 0 auto",
    color: "#fff",
    width: "1.5rem",
    height: "1.5rem",
    fontSize: "1rem",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
    overflow: "hidden",
  },
  itemImageImg: {
    display: "block",
    width: "100%",
    height: "auto",
  },
  otherContactItem: {
    padding: "1rem",
    display: "grid",
    gridTemplateColumns: "max-content minmax(auto, 220px) 4.475rem",

    [ScreenSizes.xlAndAbove]: {
      padding: "1.25rem",
      gridTemplateColumns:
        "max-content 9.375rem minmax(7.375rem, 17.563rem) 4.875rem",
    },
    gap: "1rem",
    alignItems: "center",
    borderBottom: `1px solid ${colours.borderGrey}`,

    ":last-child": {
      borderBottom: "none",
    },
  },
  otherContactItemWithVotes: {
    padding: "1rem",
    display: "grid",
    gridTemplateColumns: "1.375rem max-content minmax(auto, 11rem) 4.475rem",

    [ScreenSizes.smAndAbove]: {
      gridTemplateColumns: "1.375rem max-content minmax(auto, 1fr) 4.475rem",
    },

    [ScreenSizes.mdAndAbove]: {
      gridTemplateColumns: "1.375rem max-content minmax(auto, 13.5rem) 4.79rem",
    },

    [ScreenSizes.xlAndAbove]: {
      padding: "1.25rem",
      gridTemplateColumns:
        "1.375rem max-content 9.375rem minmax(7.375rem, 17.563rem) 4.875rem",
    },
    gap: "1rem",
    alignItems: "center",
    borderBottom: `1px solid ${colours.borderGrey}`,

    ":last-child": {
      borderBottom: "none",
    },
  },

  moderatorOtherContactItem: {
    [ScreenSizes.xlAndAbove]: {
      gridTemplateColumns:
        "max-content max-content 9.375rem minmax(7.375rem, 17.563rem) 7.7rem",
    },
  },
  responsiveType: {
    [createBreakpoint({ max: 1059 })]: {
      display: "block",
    },
    [createBreakpoint({ min: 1060 })]: {
      display: "none",
    },
  },
  otherContactItemWithCompany: {
    [createBreakpoint({ min: 1060 })]: {
      gridTemplateColumns:
        "max-content 12rem minmax(7rem, 9rem) minmax(7.375rem, 17.563rem) 4.875rem",
    },
  },
  companyColumn: {
    display: "none",

    [createBreakpoint({ min: 1060 })]: {
      display: "block",
    },
  },
  companyName: {
    ...gStyles.avalonMedium,
    margin: 0,
    fontSize: "0.875rem",
  },
  companyType: {
    ...gStyles.avalonLight,
    margin: 0,
    fontSize: "0.75rem",
  },
  contactIcon: {
    display: "flex",
    color: colours.bodyText,
    width: "1.5rem",
    height: "1.5rem",
    fontSize: "1rem",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
  },
  contactEmail: {
    ...gStyles.avalonLight,
    fontSize: "0.875rem",
    margin: 0,
    maxWidth: "10rem",

    [ScreenSizes.mdAndAbove]: {
      maxWidth: "14rem",
    },
    [ScreenSizes.lgAndAbove]: {
      maxWidth: "16.563rem",
    },
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  bold: {
    ...gStyles.avalonMedium,
  },
  contactLabelContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "0.313rem",
  },
  contactActionButtonsContainer: {
    display: "flex",
    alignItems: "Center",
    gap: "1rem",
    width: "max-content",
  },
  contactActionButtons: {
    border: "none",
    borderRadius: "0.5rem",
    height: "1.875rem",
    width: "1.875rem",
    fontSize: "1rem",
    display: "grid",
    placeItems: "Center",
    transition: "ease-out 0.2s",

    [ScreenSizes.lgAndAbove]: {
      border: `1px solid ${colours.borderGrey}`,
    },

    ":hover": {
      cursor: "pointer",
      transition: "ease-out 0.2s",
      transform: "translateY(-1px)",
    },
  },
  activeActionButton: {
    [ScreenSizes.lgAndAbove]: {
      border: `2px solid ${colours.primary}`,
    },
  },
  contactLabel: {
    ...gStyles.avalonBold,
    fontSize: "0.875rem",
    margin: 0,
  },
  topAlignment: {
    alignItems: "start",
  },
  marginTop: {
    marginTop: "0.3rem",
  },
  desktopTitleCon: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  types: {
    display: "flex",
    flexDirection: "column",
    gap: "0.25rem",
    marginTop: "0.25rem",
  },
  show: {
    color: colours.secondary,

    ":hover": {
      cursor: "pointer",
    },
  },
  badge: {
    ...gStyles.avalonBold,
    borderRadius: 25,
    padding: "0 0.5rem",
    color: colours.white,
    fontSize: "0.75rem",
  },
  brand: {
    background: colours.primary,
  },
  aor: {
    background: colours.lightLavender,
  },
  titleContainer: {
    display: "flex",
    gap: "0.313rem",

    [ScreenSizes.xlAndAbove]: {
      display: "inline",
    },
  },
  typesBold: {
    ...gStyles.avalonMedium,
    marginBottom: "0.313rem",
  },

  mainTitleContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  contactEmailBold: {
    ...gStyles.avalonMedium,

    [ScreenSizes.xlAndAbove]: {
      ...gStyles.avalonLight,
    },
  },
  mainContainer: {
    display: "block",

    [ScreenSizes.xlAndAbove]: {
      display: "contents",
    },
  },
  mobileShowCompany: {
    display: "block",

    [createBreakpoint({ min: 1060 })]: {
      display: "none",
    },
  },
  desktopShowCompany: {
    display: "none",

    [createBreakpoint({ min: 1060 })]: {
      display: "block",
    },
  },
  desktopEmail: {
    display: "none",

    [ScreenSizes.xlAndAbove]: {
      display: "block",
    },
  },
  mobileEmail: {
    display: "block",

    [ScreenSizes.xlAndAbove]: {
      display: "none",
    },
  },
};

const CONTACT_PAGE = "Contact Page";
const OTHER_CONTACT = "Other Contact";

const InsightsContactItemRow = (props) => {
  const {
    compact,
    company,
    companyTypes,
    copied,
    handleCopy,
    handleEventTracking,
    handleLinkClick,
    icon,
    image_url,
    label,
    podcastId,
    showCompany,
    showVotes,
    subLabel,
    type,
    url,
    value,
    showRss,
  } = props;

  const { styles } = useStyles(baseStyles, props);
  const [showMoreTypes, setShowMoreTypes] = useState(false);
  const hasPro = useUserHasPro();

  const showCompanyTypes = companyTypes?.size > 0;
  const typesMoreCount = companyTypes?.size - 1;
  const email = value || url;
  const isALink = url || label === CONTACT_PAGE;

  const renderToolTipLabel = useCallback(
    () => (
      <div>
        {companyTypes?.map((type, index) => {
          return (
            <h5
              key={`${type}+${index}`}
              className={css(styles.companyType, styles.typesBold)}
            >
              {type}
            </h5>
          );
        })}
      </div>
    ),
    [companyTypes, styles.companyType, styles.typesBold]
  );

  const renderMobileType = useCallback(() => {
    if (showCompanyTypes) {
      return (
        <BasicTooltip
          renderTooltip={renderToolTipLabel}
          position="top-start"
          zIndex={10000}
        >
          {(tooltipProps) => (
            <span
              {...tooltipProps}
              className={css(
                styles.badge,
                type === "Brands" ? styles.brand : styles.aor
              )}
            >
              {type}
            </span>
          )}
        </BasicTooltip>
      );
    }

    return (
      <span
        className={css(
          styles.badge,
          type === "Brands" ? styles.brand : styles.aor
        )}
      >
        {type}
      </span>
    );
  }, [
    showCompanyTypes,
    styles.badge,
    styles.brand,
    styles.aor,
    type,
    renderToolTipLabel,
  ]);

  const handleShowMore = useCallback(() => {
    setShowMoreTypes(!showMoreTypes);
  }, [showMoreTypes]);

  const renderDesktopType = useCallback(() => {
    if (showMoreTypes) {
      return (
        <>
          {companyTypes?.map((type) => {
            return <h5 className={css(styles.companyType)}>{type}</h5>;
          })}
          <span
            className={css(styles.companyType, styles.show)}
            onClick={handleShowMore}
          >
            Show Less
          </span>
        </>
      );
    }

    return (
      <h5 className={css(styles.companyType)}>
        {companyTypes?.first()}{" "}
        {typesMoreCount > 0 && (
          <span className={css(styles.show)} onClick={handleShowMore}>
            {" "}
            + {typesMoreCount} more.
          </span>
        )}
      </h5>
    );
  }, [
    companyTypes,
    handleShowMore,
    showMoreTypes,
    styles.companyType,
    styles.show,
    typesMoreCount,
  ]);

  const renderLayout = () => (
    <div className={css(styles.mainContainer)}>
      <div className={css(styles.mainTitleContainer)}>
        <p className={css(styles.contactLabel, styles.titleContainer)}>
          {label || OTHER_CONTACT}
          {showCompany && (
            <div className={css(styles.mobileShowCompany)}>
              <span className={css(styles.responsiveType)}>
                {renderMobileType()}
              </span>
            </div>
          )}
        </p>
        <p className={css(styles.contactEmail)}>{subLabel}</p>
      </div>
      {showCompany && (
        <div className={css(styles.desktopShowCompany)}>
          <div className={css(styles.companyColumn)}>
            <h5 className={css(styles.companyName)}>{company}</h5>
            {showCompanyTypes && (
              <div className={css(styles.types)}>{renderDesktopType()}</div>
            )}
          </div>
        </div>
      )}
      <div className={css(styles.desktopEmail)}>
        <p
          className={css(styles.contactEmail, styles.contactEmailBold)}
          onClick={() => handleCopy(email, handleEventTracking)}
        >
          {email}
        </p>
      </div>
      <div className={css(styles.mobileEmail)}>
        <BasicTooltip
          renderTooltip={() => (copied ? "Copied" : "Copy")}
          hideOnTouchDevice={!copied}
          position="right-start"
          zIndex={10000}
        >
          {(tooltipProps) => (
            <p
              {...tooltipProps}
              className={css(styles.contactEmail, styles.contactEmailBold)}
              onClick={() => handleCopy(email, handleEventTracking)}
            >
              {email}
            </p>
          )}
        </BasicTooltip>
      </div>
    </div>
  );

  const renderRSSFlag = () => (
    <BasicTooltip
      renderTooltip={() => "This contact was added via the podcast's RSS feed"}
      position="top-start"
      zIndex={10000}
    >
      {(tooltipProps) => (
        <span {...tooltipProps} className={css(styles.rssContainer)}>
          <FontAwesomeIcon
            icon={faRss}
            dataid="rssFlag"
            className={css(styles.rss)}
          />
          <h3 className={css(styles.rssText)}>RSS</h3>
        </span>
      )}
    </BasicTooltip>
  );

  const isFirefox = navigator.userAgent.toLowerCase().includes("firefox");

  return (
    <div
      className={css(
        styles.otherContactItem,
        showCompany && styles.otherContactItemWithCompany,
        showVotes && styles.otherContactItemWithVotes
      )}
    >
      {showVotes && <> {!showRss ? <div /> : hasPro && renderRSSFlag()} </>}
      {image_url ? (
        <span className={css(styles.itemImage)}>
          <img
            data-testid="image"
            src={image_url}
            className={css(styles.itemImageImg)}
            width={1.5 * 16}
            height={1.5 * 16}
            alt=""
          />
        </span>
      ) : (
        <span className={css(styles.contactIcon)}>
          <FontAwesomeIcon icon={icon} dataid={label} />
        </span>
      )}
      {renderLayout()}
      <div className={css(styles.contactActionButtonsContainer)}>
        <BasicTooltip
          renderTooltip={() => (copied ? "Copied" : "Copy")}
          position="top-start"
          zIndex={10000}
        >
          {(tooltipProps) => (
            <span
              {...tooltipProps}
              className={css(styles.contactActionButtons)}
              onClick={() => handleCopy(email, handleEventTracking)}
            >
              <FontAwesomeIcon icon={faCopy} dataid="copy" />
            </span>
          )}
        </BasicTooltip>
        {compact && isALink ? (
          <a
            className={css(styles.contactActionButtons)}
            href={getClickableLink(email, podcastId)}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => handleLinkClick(email)}
          >
            <FontAwesomeIcon
              dataid="link"
              icon={faUpRightFromSquare}
              className={css(styles.openIcon)}
            />
          </a>
        ) : (
          <a
            href={`mailto:${email}`}
            className={css(styles.contactActionButtons)}
            onClick={(event) => {
              if (isFirefox) {
                event.preventDefault();
                window.open(`mailto:${email}`, "mail");
              }
              handleLinkClick(email);
            }}
          >
            <FontAwesomeIcon icon={faEnvelope} dataid="envelope" />
          </a>
        )}
      </div>
    </div>
  );
};

InsightsContactItemRow.propTypes = {
  icon: PropTypes.object,
  label: PropTypes.string,
  subLabel: PropTypes.string,
  value: PropTypes.string,
  image_url: PropTypes.string,
  handleCopy: PropTypes.func,
  handleEventTracking: PropTypes.func,
  copied: PropTypes.bool,
  compact: PropTypes.bool,
  podcastId: PropTypes.number,
  showCompany: PropTypes.bool,
  podcastOwner: PropTypes.bool,
};

InsightsContactItemRow.defaultProps = {
  icon: null,
  label: "",
  subLabel: "",
  value: "",
  image_url: "",
  handleCopy: null,
  handleEventTracking: null,
  copied: false,
  compact: false,
  podcastId: null,
  showCompany: false,
  podcastOwner: false,
};

export default InsightsContactItemRow;
